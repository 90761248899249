<template>
  <div>
    <section class="section content">
      <b-image lazy v-if="logo" :src="logo">
        <template #placeholder>
          <b-skeleton class="skeleton-placeholder" height="100%"></b-skeleton>
        </template>
      </b-image>
      <h1>{{ title }}</h1>
      <json-rich-text v-if="body" :text="body" />
    </section>
  </div>
</template>

<script>
import JsonRichText from '@/components/JsonRichText.vue'
import axios from 'axios';

export default {
  components: {
    JsonRichText
  },
  data() {
    return {
      title: null,
      body: null,
      logo: null,
    };
  },
  async mounted() {
    axios.get(
      process.env.VUE_APP_API_BASE + "/api/globals/terms"
    ).then((res) => {
      this.title = res.data.title
      this.body = res.data.body
    })

    axios.get(
      process.env.VUE_APP_API_BASE + "/api/globals/setup"
    ).then((res) => {
      this.logo = res.data.appLogo.url
    })
    this.$store.commit("StopLoading");
  }
}
</script>
